.checkout-right-rail-mobile {
  margin-top: 5rem;
}

.checkoutpage-promo {
  padding: 30px 0px 0px;
}

.checkout-right-rail.order-summary-wrapper {
  padding-bottom: 0;
  margin-top: 4rem;

  .checkoutpage-promo .input-field {
    width: 100%;
  }

  .order-total-content {
    padding-bottom: 5rem;

    @include media-query(0, 540) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @include media-query(700) {
      padding-bottom: 0;
    }
  }
}

.checkout-baglist .scope-1892 {
  .productcard {
    width: 100%;
  }

  .productcard-header {
    padding-right: 0;
  }

  .product-card-ctas {
    padding-top: 0;
  }
}

#checkout-payment-mfe-portal {
  padding-left: 2rem;
  padding-right: 2rem;

  @include media-query(540) {
    padding-left: 0;
    padding-right: 0;
    margin-left: 5.5rem;
    margin-right: 5.5rem;
  }

  @include media-query(700) {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
}

.checkout-right-rail-mobile .order-submit-checkbox-wrapper {
  margin-top: -2rem;
}

#checkout-left-rail-mfe {
  margin-bottom: 5rem;
  .welcome-user-section {
    margin-left: auto;
    margin-right: auto;
    background-color: color('secondary-light');

    @include media-query(1025) {
      max-width: 512px;
    }

    .loyalty-section__wrapper {
      background-color: color('secondary-light');

      .loyalty-member-welcome {
        padding: 0 2rem 3rem 2rem;

        @include media-query(1025) {
          padding: 3rem 2rem;
        }

        .session {
          display: block;
          height: auto;
          padding: 0px;

          .icon-heading {
            align-items: center;
            flex-direction: column;
            justify-content: space-between;

            @include media-query(540) {
              flex-direction: row;
              justify-content: space-between;
            }

            .icon-heading-icon {
              margin-right: 0;
              flex-flow: column;

              @include media-query(700) {
                width: 75%;
              }

              img {
                height: 25px;
              }
            }

            .icon-heading-text {
              margin: 10px 0 0 0;
              padding-top: 0;
              padding-bottom: 0;
              text-align: left;
              line-height: 1.5384615385;

              @include media-query(1025) {
                margin: 10px 0 15px 0;
              }

              .iconHeadingReward {
                padding-left: 5px;
              }
            }
          }

          .sign-in-join {
            @include media-query(360, 540) {
              margin-top: 20px;
              width: 100%;
            }

            .button {
              @include media-query(360, 540) {
                width: 100%;
              }
            }
          }
        }

        .logged-in {
          background-color: color('secondry-light');

          .member_since {
            display: none;
          }

          .icon-block {
            .icon-block-icon {
              .icon-block-icon-image {
                height: 25px;
                width: 25px;
              }
            }
          }

          hr {
            .sign-in-rewards-separator {
              display: none;
            }

            .sign-in-separator {
              margin-left: 2rem;
              margin-right: 2rem;
            }
          }

          @include media-query(1025) {
            background-color: unset;
          }
        }

        @include media-query(540) {
          padding: 2rem;
        }
      }

      .loyalty-member-error {
        padding: 0 2rem 2rem;
      }
    }
  }
}

.show-tmnt-modal-btn.button[data-variant=borderless] {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  min-height: auto;
  padding: 0;
  text-decoration: underline;
}

#checkout-left-rail-mfe .loyalty-section {
  .rewards {

    .accordion[data-type="boxed"]:not([data-expanded-variant="true"]) .accordion-panel,
    .accordion[data-type="boxed"]:not([data-expanded-variant="true"]) [role="heading"],
    .accordion[data-type="boxed"][data-expanded-variant="true"]:not([data-expanded="true"]) .accordion-panel,
    .accordion[data-type="boxed"][data-expanded-variant="true"]:not([data-expanded="true"]) [role="heading"] {
      background-color: color('secondary-light');
    }
  }
}

#checkout-left-rail-mfe .loyalty-member-welcome {

  .sign-in-join,
  .button[data-variant='secondary'],
  .button[data-variant='secondary-button'],
  .button[data-variant='tertiary-button-dark'] {
    width: auto;
  }
}

#checkout-left-rail-mfe .welcome-user-section .loyalty-section__wrapper,
#checkout-left-rail-mfe .loyalty-member-welcome .logged-in {
  background-color: color('secondary-light');
}

.checkout-wrapper.left-rail {
  position: relative;
}

.submit-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  top: 0px;
  left: 0px;
  z-index: 10;
  opacity: 0.1;
}

.horizontal-separator {
  display: block;
  margin: 5rem auto;
  border-bottom: 1px solid color('tertiary-light');
}

.express-checkout {
  display: none;
}
