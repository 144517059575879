.sticky-bag-summary {
  background-color: color('primary-light');
  padding: 2rem 2.5rem;
  position: sticky;
  z-index: 999;

  padding: 0px;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, .36);

  @include media-query(1025) {
    display: none;
  }

  .disclosure-panel {
    padding: 2rem 2.5rem;
  }


  .label-and-button-container {
    border-bottom: 1px solid #ccc;
    padding: 2rem 2.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .label-and-button-container > * {
    flex: 1; 
    text-align: center;
  }

  #checkout-sticky-bag-summary-disclosure {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .disclosure-content {
      width: unset;
      .disclosure-label {
        margin-right: 0;
        margin-left: 40px;
      }
    }
  }
}

#checkout-sticky-bag-summary-modal {

  .productcard-content .productcard-price {
    display: none;
  }

  .product-template-item {
    padding-top: 1rem;
  }

  

  .sticky-bag-content {
    .sticky-bag-summary-baglist {
      ul {
        list-style-type: none;
        li {
          list-style-type: none;
          .product-template {
            display: block;
          }
        }
      }
      
      .product-card-ctas {
        display: none;
      }
    }
    .sticky-bag-summary-close-btn {
      margin: 0 auto;
    }
  }
}