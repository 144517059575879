.shopping-bag {
  transition: width .3s,margin .3s linear;
  
  .shopping-bag-content {
    .bag-area {
      background-color: color('secondary-light');
      transition: width .3s,margin .3s linear;
      .promotion-loader {
        opacity: 0.4;
        pointer-events: none;
      }
      .soldout-repudiation-error-block
      {
        .error-message {
          background-color: unset;
          color: color('error');
          padding: 2rem; 
          display: flex;
        }
      }
      .soldout-repudiation-error-block-tabbed {
        .error-message {
          background-color: color('primary-light');
          color: color('error');
          padding: 2rem;
          display: flex;
        }
      }
      @include media-query(1025) {
        display: flex;
        align-items: stretch;
      }
    }
    @include media-query(1025) {
      margin: 0 auto;
      overflow: auto;
      padding-top: 7rem;
      width: 100%;
    }

    @include media-query(1500) {
      width: 1300px;
    }
  }
  @include media-query(1025) {
    background-color: color('secondary-light');
  }
}

.sticky-checkout-container {
  background-color: color('primary-light');
  bottom: -21rem;
  box-shadow: 0 0 0.4rem 0 rgba(0,0,0,.25);
  transition: bottom .3s ease,box-shadow .4s ease;
  transition: bottom .3s ease,box-shadow .4s ease,-webkit-box-shadow .4s ease;
  transition: bottom .3s ease,-webkit-box-shadow .4s ease;
  padding:20px;
  position: fixed;
  width: 100%;
  z-index: 19;
  @include media-query(1025) {
    display: none;
  }

  .horizontal-or-separator {
    margin: 2rem 0;
    text-transform: lowercase;
  }

  &.sticky-slide {
    bottom: 0;
    transition: bottom .4s ease,-webkit-box-shadow .4s ease;
    transition: bottom .4s ease,box-shadow .4s ease;
  }
}
.scope-1892 .icon-button[data-theme="inverse"]:not(:disabled):hover {
  cursor: pointer;
}