.minibag-static-icon-comp{
  margin-top: 0.5rem;
  .minibag-icon-wrapper {
    #rs-nav__item-count-largescreen {
      top: 15px;
    }
}
  .minibag-nav-mfe {
    margin-top: 0.5rem;
    :hover {
      cursor: pointer;
    }
  }
  .minibag-under-icon-block {
    opacity: 0;
  }
}