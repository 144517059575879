.loyalty-rewards-section {
  .rewards-promos {
    .repudiation {
      align-items: center;
      display: flex;
      padding: 2rem;
    }
    button.button.show-tmnt-modal-btn {
      height: auto;
      min-width: auto;
    }
    .discount-card[data-orientation='stacked'] .discount-actions .discount-applied {
      display: inline-flex;
    }
  }
  .user-notification {
    font-family: inherit;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 5px 0px;
    color: color('primary-dark');
  }
}

#rewards-coupons-list {
  background-color: color('secondary-light');
}

#rewards-coupons-list-panel-id {
  background-color: color('secondary-light');
  .estimated-points-section p {
    display: inline;
  }
  .coupon-list {
    .discount-card {
      margin: 2rem 0; 
      .discount-content {
        background: url(https://img.abercrombie.com/is/image/anf/background-ticket.svg);
        display: block;
        .discount-actions {
          margin: 15px 0 0 0;
          .redeem-button {
            width: 100%;
          }
        }
      }
  }
  }
}