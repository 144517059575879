.selected-store-wrapper {
  .store-card {
    border: 1px solid color('tertiary-light');

    @include media-query(700) {
      display: flex;
    }

    @include media-query(1025) {
      display: flex;
    }

    .store-card-left-wrapper {
      display: flex;
      align-items: center;
      background-size: cover;
      background: color('secondary-light') no-repeat 50%;
      justify-content: space-around;
      min-height: 168px;
      padding: 2rem;
      background-position: center;
      /* Center the image */
      background-repeat: no-repeat;
      /* Do not repeat the image */
      background-size: cover;

      /* Resize the background image to cover the entire 
      container */
      @include media-query(700) {
        border-bottom-style: none;
        height: auto;
        width: 20rem;
      }

      .store-information {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .store-card-right-wrapper {
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 1.5rem 0;
      width: 100%;

      @include media-query(1025) {
        width: calc(100% - 20rem);
        padding: 1.5rem;
      }

      .store-card-content {
        padding: 1rem;

        .store-icon-block-wrapper {
          display: flex;
          width: 100%;

          .store-card-icon-block-content {
            margin-left: 1rem;
            width: 100%;

            .address-information {
              margin-bottom: 1rem;

              .address-header {
                display: flex;
                justify-content: space-between;

                .btn-change {
                  background: none;
                  border: none;
                  outline: none;
                  text-decoration: underline;
                  color: inherit;
                  cursor: pointer;
                }
              }
            }

            .disclosure {
              #disclosure-store-hours {
                .disclosure-label {
                  margin-right: 24px;
                }
              }
            }
          }
        }

        .omni-info-block {
          width: 100%;

          .omni-info-text-container {
            color: color('green-500');
            font-weight: 700;

            .icon-block-wrapper {
              display: flex;

              .omni-info-text-wrapper {
                margin-left: 1rem;
              }
            }

            .estimated-pickup-date {
              color: color('navy-700');
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}