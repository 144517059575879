#popins-modal {

  .popins-stores-list {
    list-style: none;
  }

  .popins-search-wrapper {
    margin: 0px 0px 0px 0px;
  }

  .nearme-button {
    height: 100%;
    padding: 0px 20px;
    min-width: 130px;
  }

  .popins-search-input {
    width: 100%;
  }

  .popins-search-button {
    max-width: 70px;
    padding-right: 5px;
  }
  
  .button[data-variant="secondary"] > [data-icon] {
    margin-right: 0px;
  }

  .button[data-variant="secondary"] > [data-icon="search"] {
    max-width: 70px;
    padding-right: 7px;
  }

  .nearme-button-text {
    margin-left: 5px;
  }

  .popins-modal-hr {
    border-style: none;
    border-top-style: none;
    border-top: 1px solid #ccc;
  }

  .popins-modal-hr:first-of-type {
    margin: 40px 0px 25px;
  }

  .popins-modal-hr:last-of-type {
    margin: 10px 0px;
  }
  
  .locations-near-text {
    margin-bottom: 25px;
  }

  .popins-store-card-wrapper {
    padding-bottom: 20px;
  }

  .store-card-content {
    width: 100%;
  }

  .first-row {
    display: flex;
  }

  .can-pickup {
    color: color('cta');
  }

  .cannot-pickup {
    color: color('heading');
  }

  .omni-info-text {
    display: flex;
    font-weight: 700;
    margin-left: 10px;
  }

  .second-row {
    margin-left: 30px;
  }

  .horizontal-separator-wrapper {
    margin: 15px 0px;
  }

  .select-store-button {
    margin-top: 15px;
  }

  .no-stores {
    color: color('error');
    margin-bottom: 25px;
  }

  .popins-error {
    margin-bottom: 25px;
  }

  .load-more-button {
    display: block;
    padding-bottom: 10px;
    padding-top: 15px;
    text-align: center;
  }

  // 700 px and smaller
  // Band-aid fix for DS StoreCard styles
  @media only screen and (max-width: 700px) {
    .popins-store-card-wrapper {
      .store-card-jsx{
        flex-direction: column;
  
        .store-card-left-container {
          width: 100%;
        }
        .store-card-right-container {
          flex-direction: row;
          width: 100%;
        }
      }
    }
  }
}
