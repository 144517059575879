.left-rail {
  .left-container {
    .page-header {
      padding: 2.5rem 2rem 3rem 2rem;
      @include media-query(700){
        padding: 2.5rem 2rem 3rem 0
      }
      .logoff-trigger{
        flex: 30%;
        justify-content: flex-end;
      }
    }
    .welcome-user-section {
      background-color: color('secondary-light');
      .loyalty-section__wrapper {
        .loyalty-member-welcome {
          padding: 0 2rem 3rem 2rem;
          @include media-query(1025){
            padding: 3rem 2rem;
          }
          .session {
            display: block;
            .icon-heading {
              align-items: center;
              flex-direction: column;
              justify-content: space-between;
              @include media-query(540){
                flex-direction: row;
                justify-content: space-between;
              }
              .icon-heading-icon {
                margin-right: 0;
                @include media-query(700){
                  width: 75%;
                }
                img {
                  height: 25px;
                }
              }
              .icon-heading-text {
                margin: 10px 0 0 0;
                padding-top: 0;
                padding-bottom: 0;
                text-align: left;
                @include media-query(1025){
                  margin: 10px 0 15px 0;
                }
                .iconHeadingReward {
                  padding-left: 5px;
                }
              }
            }
            .sign-in-join{
              @include media-query(360, 540){
                margin-top: 20px;
                width: 100%;
              }
              .button {
                @include media-query(360, 540){
                width: 100%;
                }
              }
            }
          }
          .logged-in {
            background-color: color('secondry-light');
            .member_since {
              display: none;
            }
            .icon-block{
              .icon-block-icon {
                .icon-block-icon-image {
                  height: 25px;
                  width: 25px;
                }
              }
            }
            hr {
              .sign-in-rewards-separator {
                display: none;
              }
              .sign-in-separator {
                margin-left: 2rem;
                margin-right: 2rem;
              }
            }
            @include media-query(1025) {
              background-color: unset;
            }
          }
          
          @include media-query(540){
            padding: 2rem;
          }
        }
        .loyalty-member-error {
          padding: 0 2rem 2rem;
        }
      }
    }
    
    .loyalty-section {
      // margin-bottom: 30px;
      .rewards {
        .accordion[data-type="boxed"]:not([data-expanded-variant="true"]) .accordion-panel,
        .accordion[data-type="boxed"]:not([data-expanded-variant="true"]) [role="heading"],
        .accordion[data-type="boxed"][data-expanded-variant="true"]:not([data-expanded="true"]) .accordion-panel,
        .accordion[data-type="boxed"][data-expanded-variant="true"]:not([data-expanded="true"]) [role="heading"]  {
          background-color: color('secondary-light');
          @include media-query(1025) {
            background-color: unset;
          }
        }
      }
    }
    .product-rail[data-state=true] {
      display: none;
      .disable-baglist-pointer-events {
        pointer-events: none;
      }
    }
    .product-rail[data-state=false] {
      display: block;
      padding-top: 1rem;
      padding-bottom: 5rem;
      @include media-query(1025) {
        padding-top: 2rem;
      }
    }
    .empty-bag-text {
      background-color: color('primary-light');
      padding: 3rem 2rem 0;
      @include media-query(1025) {
        padding: 3rem 0 0;
      }
    }
    .product-rail-tabbed-view[data-state=true] {
      .error-section {
        margin: 2rem 0;
        padding: 0 2rem;
        @include media-query(1025) {
          padding: 0;
        }
      }
      .tab-button-sub-label {
        display: none;
      }
      background-color: color('primary-light');
      padding-top: 1rem;
      padding-bottom: 5rem;
      @include media-query(1025) {
        padding-top: 2rem;
      }
      .disable-baglist-pointer-events {
        pointer-events: none;
      }
      .shopping-bag-list-mfe {
        background-color: color('primary-light');
        padding: 0 2rem;
        @include media-query(1025) {
          padding: 0;
        }
      }
      .repudiation-error {
        .shopping-bag-list-mfe {
          padding: 0;
        }
      }
      .tab-button {
        max-width: 50%;
      }
    }
    ul {
      list-style-type: none;
    }
    .shopping-bag-list-mfe {
      background-color: color('primary-light');
      padding: 0 2rem;
      @include media-query(1025) {
        padding: 0;
      }
      .product-template-item {
        border-bottom: 1px solid color('tertiary-light');
        .productcard {
          border-bottom: none;
        }
        .error-section {
          margin-bottom: 2rem;
          .error-message {
            background-color: unset;
            color: color('error');
            padding: 0;
          }
        }
        .shopping-bag-product-template {
          display: block;
        }
      }
    }
    .repudiation-error {
      .shopping-bag-list-mfe {
        padding: 0;
      }
    }
    .bag-item-count {
      padding-left: 5px;
    }
    @include media-query(1025) {
      float: none;
      margin: 0 auto;
      margin-top: 4rem;
      width: 70%;
      padding-top: 0;
    }
    &.sfl {
      padding-top: 3rem;
      @include media-query(1025){
        padding-top: 0;
      }
      .page-header {
        display: none;
        @include media-query(1025){
          display: block;
        }
        .bag-item-count {
          display: none;
        }
      }
      .soldout-repudiation-error-block {
        display: none;
      }
    }
  }
  @include media-query(700) {
    margin-left: auto;
    margin-right: auto;
    max-width: 589px;
  }
  @include media-query(1025) {
    background-color: color('primary-light');
    clear: both;
    display: block;
    float: left;
    max-width: none;
    padding-bottom: 4rem;
    width: 66%;
  }
}