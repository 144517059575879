.recognize-customer-section {
  height: 100vh;
  margin: 0 auto;
  max-width: 50rem;
  width: 100%;
  background-color: color('primary-light');
  @include media-query(1025) {
    background-color: unset;
  }

  .checkout-heading {
    padding: rem(50) rem(25) 0 rem(25);
    @include media-query(700,1025){
      padding: rem(40) rem(25);
    }
    @include media-query(1025) {
      padding: 0 0 rem(10) rem(25);
    }
  }

  .error-message-banner-container {
    padding: 1rem 0rem 1rem 2.5rem;
  }

  .inner-wrapper {
    background-size: contain;
    padding: rem(35) rem(25);
    text-align: center;
  }

  .header {
    margin-bottom: rem(30);
    font-size: rem(15);
    text-align: left;

    p {
      font-size: rem(15);
      line-height: px(22);
      margin-top: rem(15);
    }
  }

  .forgot-password {
    text-align: left;
    width: 100%;
    justify-content: left;
    margin-top: rem(10);
    margin-bottom: rem(20);
  }

  .join-link-container-account {
    text-align: center;
  }

  .horizontal-or-separator{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .first {
    margin-bottom: rem(0);
    margin-top: rem(30);
  }

  button {
    cursor: pointer;
  }

  .error-message{
    text-align: left;
  }

  .signedIn-checkbox-content {
    text-align: left;
  }

  .join-link-container-account {
    display: none;
    margin-top: rem(20);
  }

  @include media-query(700) {
    .inner-wrapper {
      padding: 0 rem(25);
      text-align: left;

      .h2 {
        display: none;
      }
    }
  }
}

#recognizeCustomerForm__errorMessage {
  margin-top: 10px;
}

#checkout-top-rail-mfe-portal{
  width: 100%;
}

#checkout-top-rail-mfe-portal.recognize-customer-mfe-disabled,
#checkout-top-rail-mfe-portal.recognize-customer-mfe-hide {
  width: auto;
  .recognize-customer-mfe {
    display: none;
  }
}