.minibag-with-icon-comp{
  margin-top: 0.5rem;
  .minibag-icon-wrapper {
    #rs-nav__item-count-largescreen {
      top: 15px;
    }
}
  .minibag-nav-largescreen {
    margin-top: 0.5rem;
    background-color: transparent;
    border: none;
    position: relative;
    display: block;
    font-family: inherit;
    height: 75px;
    line-height: 55px;
    min-width: 50px;
    text-align: center;
    width: 100%;
    color: color('primary-dark');
    font-size: 1.7rem;
    :hover {
      cursor: pointer;
    }
    .mini-bag-a11y-desc {
      display: none;
    }
  }
  .minibag-under-icon-block {
    opacity: 0;
  }
}

.minibag-nav-smallscreen-legacy {
  position: absolute;
  right: 1rem;
  top: 0;
}

.minibag-nav-smallscreen-mfe {
  position: relative;
  margin-right: 1rem;
}

.minibag-nav-smallscreen {
  color: color('primary-dark');
  background: transparent;
  border: transparent;
  height: 55px;
  line-height: 55px;
  width: 35px;
  @include media-query(1025) {
    text-decoration: none;
  }
  #rs-nav__item-count-smallscreen {
    margin-left: 2rem;
    top: 1rem;
  }
}

.item-count-badge {
  align-items: center;
  background-color: color('error');
  border: 1px solid transparent;
  border-radius: 10px;
  color: color('neutral-000');
  display: none;
  font-size: 1.1rem;
  font-weight: 700;
  font-family: inherit;
  height: 20px;
  justify-content: center;
  letter-spacing: 0;
  position: absolute;
  width: 20px;

  @include media-query(1025) {
    left: 25px;
    top: 15px;
  }
}
.has-count {
  display: flex;
}
.legacy-mobile-minibag {
  display: none;
}